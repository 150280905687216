import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import {BootstrapVue} from 'bootstrap-vue';
import VueRx from 'vue-rx';
import VueCookies from 'vue-cookies'

Vue.use(TurbolinksAdapter);
Vue.use(BootstrapVue);
Vue.use(VueRx);
Vue.use(VueCookies);

Vue.component('FieldAddress', () => import('./FieldAddress'));
Vue.component('EmdCalendar', () => import('./Calendar'));
Vue.component('EmdMeals', () => import('./Meals'));
Vue.component('EmdMealPicker', () => import('./MealPicker'));
Vue.component('EmdUserPreference', () => import('./UserPreference'));
Vue.component('EmdAddressForm', () => import('./AddressForm'));
Vue.component('EmdCartList', () => import('./CartList'));
Vue.component('EmdCreditPurchaseForm', () => import('./CreditPurchaseForm'));
Vue.component('EmdCheckoutForm', () => import('./CheckoutForm'));
Vue.component('EmdOrdersTable', () => import('./OrdersTable'));
Vue.component('EmdGiftCardForm', () => import('./GiftCardForm'));
Vue.component('EmdPhoneNumberField', () => import('./PhoneNumberField'));
Vue.component('EmdCurrencyField', () => import('./CurrencyField'));
Vue.component('EmdPriceSummary', () => import('./PriceSummary'));
Vue.component('EmdPayment', () => import('./Payment'));
Vue.component('EmdCartStepper', () => import('./CartStepper'));
Vue.component('EmdPurchaseCreditsParams', () => import('./PurchaseCreditsParams'));
Vue.component('EmdCountdown', () => import('../../common/Countdown'));
Vue.component('EmdRedeem', () => import('./GiftCardRedeem'));
Vue.component('EmdCreditCardImg', () => import('./CreditCardImg'));
Vue.component('EmdShortCardForm', () => import('./ShortCardForm'));
Vue.component('EmdCreditCardForm', () => import('./CreditCardForm'));
Vue.component('EmdMenu', () => import('./Menu'));
Vue.component('EmdDistributionModal', () => import('./DistributionModal'));
Vue.component('EmdToggleSection', () => import('./ToggleSection'));
Vue.component('QuantityCheckButtonWrapper', () => import('./QuantityCheckButtonWrapper'));
Vue.component('EmdDishCardModal', () => import('./DishCardModal'));
Vue.component('EmdFindOutSource', () => import('./FindOutSource'));

$(document).on('turbolinks:load', () => {
  const el = $('#jsVue');
  if(!el.get(0)) {
    return;
  }
  new Vue({
    el: '#jsVue',
  });
});
